<template>
  <section class="rims-config-tpms-kit-overview">
    <rimconfig-breadcrumb
      :breadcrumb-items="breadcrumbItems"
      @on-click-brand="onClickBrand"
    />
    <v-container>
      <tpms-kit-overview />
    </v-container>
  </section>
</template>

<style lang="scss">
@import "@/styles/pages/rims-config-tpms-kit-overview.scss";
</style>

<script>
import RimconfigBreadcrumb from '@/components/RimconfigBreadcrumb';
import TpmsKitOverview from '@/components/TpmsKitOverview';
import { breadcrumbItems } from './configs.js';

export default {
  components: {
    RimconfigBreadcrumb,
    TpmsKitOverview,
  },
  computed: {
    breadcrumbItems() {
      return breadcrumbItems;
    },
  },
  methods: {
    onClickBrand() {
      this.$router.push(this.$i18nRoute({
        name: 'rims-config-tpmsKit',
      }));
    },
  },
};
</script>