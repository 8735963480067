<template>
  <div
    class="tpms-kit-card"
    @click="onClickTpmsKit"
  >
    <div class="tpms-kit-image-wrapper">
      <div
        v-if="tpmsKit.imageTemplate"
        :style="{ backgroundImage: `url(${getTpmsKitImage(tpmsKit.imageTemplate)})` }"
        class="tpms-kit-image"
      />
    </div>
    <div class="tpms-kit-desc-wrapper">
      <span class="design">{{ tpmsKit.man }}</span>
      <span class="description">{{ `${tpmsKit.description1} ${tpmsKit.description2}` }}</span>
      <img
        :src="`https://cdn.jfnet.de/tpmsImg/${tpmsKit.manufacturer.replace(/\s/g, '').toUpperCase()}/200x80-cc-xxxxxx.png`"
        class="brand-image"
      >
    </div>
  </div>
</template>

<style lang="scss">
@import "@/styles/components/tpms-kit-card.scss";
</style>

<script>
import { getImageBySize } from '@/utils';

export default {
  props: {
    tpmsKit: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    onClickTpmsKit() {
      this.$emit('on-click-tpms-kit');
    },
    getTpmsKitImage(image) {
      return getImageBySize(image, 250, 250);
    },
  },
};
</script>