<template>
  <div class="tpms-kit-overview">
    <div
      v-if="isLoadingTpmsKit"
      class="loading__wrapper"
    >
      <div class="loader">
        <v-progress-circular
          indeterminate
          color="accent"
        />
      </div>
    </div>
    <div
      v-else
      class="tpms-kit-card__wrapper"
    >
      <tpms-kit-card
        v-for="(item, index) in tpmsKitsOnlyData"
        :key="`${item.man}${index}`"
        :tpms-kit="item"
        @on-click-tpms-kit="onClickTpmsKit(item)"
      />
    </div>
  </div>
</template>

<style lang="scss">
@import "@/styles/components/tpms-kit-overview.scss";
</style>

<script>
import TpmsKitCard from '@/components/TpmsKitCard';
import { mapState } from 'vuex';

export default {
  components: {
    TpmsKitCard,
  },
  data() {
    return {
      isLoadingTpmsKit: false,
    };
  },
  computed: {
    ...mapState('rimconfigTpms', ['tpmsKitsOnlyData']),
    getSearch() {
      return this.$route.query.search;
    },
  },
  created() {
    if (!this.getSearch) {
      if (this.tpmsKitsOnlyData.length === 0) {
        this.isLoadingTpmsKit = true;
        this.$store.dispatch('rimconfigTpms/loadTpmsKitsOnly')
          .finally(() => {
            this.isLoadingTpmsKit = false;
          });
      }
    } else {
      if (this.tpmsKitsOnlyData.length === 0) {
        this.isLoadingTpmsKit = true;
        let payload = {};
        if (this.getSearch.length === 13) {
          payload = {
            eans: this.getSearch,
          };
        } else {
          payload = {
            mans: this.getSearch,
          };
        }
        this.$store.dispatch('rimconfigTpms/loadTpmsKitsOnly', {
          ...payload,
        })
          .then(() => {
            this.isLoadingTpmsKit = false;
            if (this.tpmsKitsOnlyData.length === 0) {
              this.$store.dispatch('dialog/setShowErrorDialog', {
                status: true,
                title: this.$t('langkey.no-tpms-kits-found-header'),
                message: this.$t('langkey.no-tpms-kits-found-message'),
              });
              this.toTpmsKit();
            }
          })
          .catch(() => {
            this.isLoadingTpmsKit = false;
            this.$store.dispatch('dialog/setShowErrorDialog', {
              status: true,
              title: this.$t('langkey.no-tpms-kits-found-header'),
              message: this.$t('langkey.no-tpms-kits-found-message'),
            });
            this.toTpmsKit();
          });
      }
    }
  },
  methods: {
    onClickTpmsKit(item) {
      this.$router.push(
        this.$i18nRoute({
          name: 'rims-config-tpmsKit_design-details',
          query: {
            search: this.getSearch,
            man: item.man,
          },
        }),
      );
    },
    toTpmsKit() {
      this.$router.push(
        this.$i18nRoute({
          name: 'rims-config-tpmsKit',
        }),
      );
    },
  },
};
</script>